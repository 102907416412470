/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Header from "./header"
import Footer from "./footer"
import "../../node_modules/bootstrap/dist/css/bootstrap.css"
import "./../styles/base.scss"

const Layout = ({ children, intl }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={intl.formatMessage({ id: "title" })} />
        <div>
          <main>{children}</main>
          <Footer />
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default injectIntl(Layout)
