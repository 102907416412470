import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import Language from "./language"

const Header = ({ intl }) => (
  <header>
    <div className="container">
      <div className="grd-colum --lang">
        <Language />
      </div>
      <div className="grd-colum">
        <Link className="logo" to="/">
          <img src={"/asigning1.png"} alt="" />
        </Link>
        <div className="grid-social">
          <Link className="btn bt-connect" to="/projects/">
            <FormattedMessage id="header.nameProject" />
          </Link>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
