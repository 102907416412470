//import { Link } from "gatsby"
//import PropTypes from "prop-types"
//import { FormattedMessage } from "gatsby-plugin-intl"
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import React from "react"

const Footer = ({ intl }) => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-lg-2 col-md-2 col-4">
          <div className="list-foot">
            <p className="title">
              <FormattedMessage id="footer.titleOne" />
            </p>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://ods.ai/en/"
                >
                  AI
                </a>
              </li>
              <li>
                <Link  rel="noopener noreferrer" className="nav-link" to="/projects/" target="_blank">
                  <FormattedMessage id="header.nameProject" />
                </Link>
              </li>
              {/* <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="http://waytopages.com"
                >
                  Waytopages
                </a>
              </li> */}
              {/* <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="http://lubumas.com/"
                >
                  lubumas
                </a>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-4">
          <div className="list-foot">
            <p className="title">
              <FormattedMessage id="footer.titleTwo" />
            </p>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://slack.com/intl/es-co/"
                >
                  Slack
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://trello.com/"
                >
                  Trello
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://github.com/diegoachury"
                >
                  Github
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-4">
          <div className="list-foot">
            <p className="title">
              <FormattedMessage id="footer.titleTree" />
            </p>
            <ul>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://www.youtube.com/channel/UCnUYZLuoy1rq1aVMwx4aTzw"
                >
                  Chrome Developers
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://foundation.mozilla.org/es/"
                >
                  Mozilla
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link"
                  href="https://evernote.com/intl/es"
                >
                  Evernote
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-6 col-md-3 col-sm-12 row_flot ">
          <div className="list-foot line_item">
            <img className="firm" src={"/asigning1.png"} alt="" />
            <div>
              <p className="title">
                <FormattedMessage id="footer.disc" />
              </p>
              <div className="ctn_line">
                {/* <img class="hand" src={"/Emoji_u1f44b.svg"} alt="" /> */}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://www.instagram.com/diachury/"
                >
                  <img src={"/instagram.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://www.linkedin.com/in/diegoachury"
                >
                  <img src={"/linkedin.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className="social-data"
                  href="https://medium.com/@diegoachury"
                >
                  <img src={"/medium.svg"} alt="" />
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=573007983173&text=Hola%20me%20gustaria%20tu%20ayuda%20en%20un%20proyecto"
                >
                  <img src={"/whatsapp-glyph-black.svg"} alt="" />
                </a>
              </div>
            </div>
            {/* <button className="btn bt-contact">Toca aqui</button> */}
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
