import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

const languageName = {
  en: "EN",
  es: "ES",
}

const Language = () => {
  return (
    <div className="language">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <button
              className="ln"
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? `#fff` : `#1C265E`,
                backgroundColor:
                  currentLocale === language ? `#212529` : `#fff`,
              }}
            >
              {languageName[language]}
            </button>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language
